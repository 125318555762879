<template>
  <div>
    <LayoutDataTable :isLoading="isLoading" :searchQuery="filter.searchQuery" :perPage="pagination.perPage"
      :meta="metaData" :currentPage="pagination.currentPage" :totals="pagination.totals"
      :searchPlaceholder="$t('lbl_search_name')" @setPerPage="(value) => ((pagination.perPage = value), (pagination.currentPage = 1))
        " @setCurrentPage="(value) => (pagination.currentPage = value)" @setSearchQuery="(value) => ((filter.searchQuery = value), (pagination.currentPage = 1))
          ">
      <template #default>
        <b-table ref="refListPartnerUsers" responsive="sm" class="position-relative" striped hover
          :empty-text="$t('lbl_no_data')" :items="listPartnerUsers" :fields="listPartnerUsersFields">
          <template #cell(name)="data">
            <div>
              <b>{{ data.item.user_info.name }}</b>
            </div>
          </template>
          <template #cell(role_description)="data">
            <div>
              <b v-if="data.item.partner_role.role_description">{{ data.item.partner_role.role_description }}</b>
              <span v-else>--</span>
            </div>
            <div v-if="data.item.partner_role.role_summary">
              <small><em>{{ data.item.partner_role.role_summary }}</em></small>
            </div>
          </template>
          <template #row-details="row">
            <div v-if="row.item.is_edit">
              <validation-observer :ref="`updatePartnerUser${row.item.id}`">
                <b-form>
                  <b-row>
                    <b-col md="12">
                      <b-form-group :label="$t('Chọn vai trò')" class="mb-2 required">
                        <validation-provider #default="{ errors }" :name="$t('Chọn vai trò')" rules="required">
                          <v-select :placeholder="$t('Chọn vai trò')" v-model="row.item.partner_role"
                            label="role_description" :clearable="false" :filterable="false" :options="listRoles || []"
                            @open="onOpen" @close="onClose" @input="(value) => handleChangeRoleUpdate(value, row.item)"
                            @search="handleDebounce">
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon icon="ChevronDownIcon" size="24" />
                              </span>
                            </template>
                            <template #no-options>{{ $t("lbl_no_data") }}</template>
                            <template #option="{ role_description, role_summary }">
                              <b class="d-block">{{ role_description }}</b>
                              <small class="d-block fs-12"><em>{{ role_summary }}</em></small>
                            </template>
                          </v-select>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group :label="$t('Quyền hạn của vai trò được chọn')" class="mb-2"
                        v-slot="{ ariaDescribedby }">
                        <b-form-checkbox-group id="checkbox-partner-permissions"
                          class="d-flex flex-wrap gap-3 pointer-events-none cursor-default"
                          v-model="row.item.partner_role.permission_ids" :options="listPermissions"
                          :aria-describedby="ariaDescribedby" value-field="permission_id"
                          text-field="permission_info.description" name="partner-permissions" switches :disabled="true"
                          @input="handleChangePermission"></b-form-checkbox-group>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="d-flex justify-content-end">
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" variant="outline-primary"
                        :disabled="isLoading" @click.stop="() => resetPartnerUser(row.item, row.index)">
                        <span>{{ $t("lbl_reset_data") }}</span>
                      </b-button>
                      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" variant="primary"
                        :disabled="isLoading"
                        @click.stop="() => updatePartnerUser(row.item, $refs[`updatePartnerUser${row.item.id}`], fetchPartnerUsers)">
                        <span>{{ $t("lbl_update") }}</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <ul v-else class="pl-2 mb-0" :set="permissions = row.item.partner_role.role_permissions">
              <li v-for="(item) in permissions" :key="item.id">
                <span>{{ item.permission_info.description }}</span>
              </li>
            </ul>
          </template>
          <!-- ACTION COLUMN -->
          <template #cell(actions)="{ item, toggleDetails, detailsShowing }">
            <div class="action text-right text-nowrap">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" :disabled="isLoading"
                size="sm" @click.stop="() => handleToggleDetail(item, toggleDetails)">
                <feather-icon size="16" icon="EyeOffIcon" v-if="detailsShowing" />
                <feather-icon size="16" icon="EyeIcon" v-else />
              </b-button>
              <template v-if="!isOwnerAndMe(item)">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" variant="outline-primary"
                  :disabled="isLoading" size="sm"
                  @click.stop="() => handleOpenEdit(item, detailsShowing, toggleDetails)">
                  <feather-icon size="16" icon="EditIcon" />
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="ml-1" variant="outline-danger" size="sm"
                  @click="() => handleDelete(item)">
                  <feather-icon size="16" icon="Trash2Icon" />
                </b-button>
              </template>
            </div>
          </template>
        </b-table>
      </template>
    </LayoutDataTable>
  </div>
</template>

<script>
import LayoutDataTable from "@/layouts/table/LayoutDataTable.vue";
import i18n from "@/libs/i18n";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useActionsConfigRoles from "./useActionsConfigRoles";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    LayoutDataTable,
  },
  directives: {
    Ripple,
  },
  props: {
    count: Number,
  },
  setup() {
    const mixData = useActionsConfigRoles();
    return { ...mixData };
  },
  data() {
    return {
      required,
      appUtils,
    }
  },
  computed: {
    metaData() {
      const localItemsCount = this.$refs.refListPartnerUsers
        ? this.$refs?.refListPartnerUsers?.localItems?.length
        : 0;
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals,
      };
    },
  },
  mounted() {
    this.fetchPartnerUsers();
  },
  watch: {
    "pagination.perPage"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchPartnerUsers();
    },
    "pagination.currentPage"() {
      this.fetchPartnerUsers();
    },
    "filter.searchQuery"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchPartnerUsers();
    },
    "filter.returnResultStatus"() {
      if (this.pagination.currentPage !== 1) this.pagination.currentPage = 1;
      this.fetchPartnerUsers();
    },
    count(data) {
      if (data > 0) {
        this.fetchPartnerUsers();
      }
    }
  },
  methods: {
    isOwnerAndMe(data) {
      const userData = appUtils.getLocalUser();
      const supplierInfo = userData?.supplierInfo
      const orgInfo = userData?.members?.find(item => item?.org_id === supplierInfo?.org_id)
      return orgInfo?.organization?.owner_id === data?.user_info?.id || userData?.id === data?.user_info?.id
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer?.observe(this.loadInfinity);
      }
    },
    onClose() {
      this.observer?.disconnect();
    },
    handleDebounce: appUtils.debounce(function (search) {
      this.fetchOrgMembers(search);
    }, 500),
    handleToggleDetail(item, toggleDetails) {
      toggleDetails();
      item.is_edit = false;
    },
    handleOpenEdit(item, detailsShowing, toggleDetails) {
      this.listPartnerUsers.forEach((user) => {
        user.is_edit = user.id === item.id
      })
      if (!detailsShowing) {
        toggleDetails();
      } else {
        this.handleToggleDetail(item, toggleDetails)
      }
    },
    resetPartnerUser(item, index) {
      this.fetchPartnerUsers()
      const originalData = JSON.parse(JSON.stringify(item?.original_data || this.listPartnerUsersRaw[index]?.original_data))
      this.$set(this.listPartnerUsers, index, originalData)
    },
    async handleDelete(item) {
      try {
        if (!item?.id) return
        const self = this;

        const h = this.$createElement;
        const messageVNode = h("div", { class: [] }, [
          "Bạn có chắc muốn xoá ",
          h("strong", `"${item.user_info?.name}"`),
          " ra khỏi danh sách?",
        ]);

        self.$bvModal
          .msgBoxConfirm([messageVNode], {
            title: "Xác nhận xóa",
            size: "sm",
            okVariant: "danger",
            okTitle: "Xác nhận",
            cancelTitle: "Huỷ bỏ",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          })
          .then(async (value) => {
            if (value) {
              await self.$rf.getRequest("AuthRequest").deletePartnerUser(item.id).then(res => {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t("Xoá dữ liệu thành công"),
                    icon: "AlertTriangleIcon",
                    variant: "success",
                  },
                });
                self.fetchPartnerUsers();
              });
            }
          })
          .catch((error) => {
            console.log(error);
            self.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_error_fetching_data"),
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>
